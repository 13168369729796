import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'
import { Spring } from 'react-spring/renderprops'
import { useWindowScroll } from 'react-use'
import { easeQuadInOut } from 'd3-ease'
import cx from 'clsx'

import { HeroContext } from '../layout'
import * as styles from './HeroBanner.module.scss'
import { ParallaxParticles } from './ParallaxParticles'
import { ArrowIcon } from '../Icons/Arrow'

const layer1 = (y) => `translate3d(0,${y / 2}px,0)`
const layer2 = (y) => `translate3d(0,${y / -5}px,0)`
const layer3 = (y) => `translate3d(0,${y / 4}px,0)`

const HeroBanner = ({ header, big, children, darkNextSection }) => {
  const { y } = useWindowScroll()
  const { darkHero } = useContext(HeroContext)

  const [particlesLoaded, setParticlesLoaded] = useState(false)
  const particleLayerClassName = cx([styles.heroParticles, particlesLoaded || styles.hide])

  const [animatedProps, setAnimatedProps] = useSpring(() => ({
    y: 0,
    config: { mass: 10, tension: 500, friction: 150, precision: 1 },
  }))

  useEffect(() => {
    setAnimatedProps({ y })
  }, [y, setAnimatedProps])

  return (
    <div className={cx([styles.heroBannerContainer, darkNextSection && styles.heroBannerContainerDarkOverlay])}>
      <div className={cx([styles.heroBanner, darkHero && styles.heroBannerDark])}>
        <Spring
          delay={1200}
          config={{ tension: 220, mass: 1.5 }}
          to={{ opacity: particlesLoaded ? 1 : 0, translate: particlesLoaded ? 0 : 70 }}
        >
          {({ opacity, translate }) => (
            <>
              <h1
                style={{ opacity, transform: `translateX(${translate}px)` }}
                className={cx([styles.heroBanner__header, big && styles.heroBanner__headerBig])}
              >
                {header}
              </h1>
              <p style={{ opacity, transform: `translateX(${-translate}px)` }} className={styles.heroBanner__text}>
                {children}
              </p>
            </>
          )}
        </Spring>
        <Spring
          delay={1600}
          config={{ easing: easeQuadInOut, duration: 700 }}
          to={{ opacity: particlesLoaded ? 1 : 0 }}
        >
          {({ opacity }) => (
            <div
              style={{ opacity }}
              className={cx([styles.heroBanner__scrollIncentive, darkHero && styles.heroBanner__scrollIncentiveDark])}
            >
              <ArrowIcon className={styles.heroBanner__scrollIncentive__icon} turn="down" dark={darkHero} />
              <span className={styles.heroBanner__scrollIncentive__text}>SCROLL</span>
            </div>
          )}
        </Spring>
        <animated.div
          className={particleLayerClassName}
          style={{ transitionDelay: '0.3s', transform: animatedProps.y.interpolate(layer1) }}
        >
          <ParallaxParticles
            onLoad={() => setParticlesLoaded(true)}
            className={cx([styles.heroParticles__element, styles.heroParticles__element1])}
          />
        </animated.div>
        <animated.div
          className={particleLayerClassName}
          style={{ transitionDelay: '0.6s', transform: animatedProps.y.interpolate(layer2) }}
        >
          <ParallaxParticles className={cx([styles.heroParticles__element, styles.heroParticles__element2])} />
        </animated.div>
        <animated.div
          className={particleLayerClassName}
          style={{ transitionDelay: '0.6s', transform: animatedProps.y.interpolate(layer2) }}
        >
          <ParallaxParticles className={cx([styles.heroParticles__element, styles.heroParticles__element3])} />
        </animated.div>
        <animated.div
          className={particleLayerClassName}
          style={{ transitionDelay: '0.9s', transform: animatedProps.y.interpolate(layer3) }}
        >
          <ParallaxParticles className={cx([styles.heroParticles__element, styles.heroParticles__element4])} />
        </animated.div>
        <animated.div
          className={particleLayerClassName}
          style={{ transitionDelay: '0.6s', transform: animatedProps.y.interpolate(layer2) }}
        >
          <ParallaxParticles className={cx([styles.heroParticles__element, styles.heroParticles__element5])} />
        </animated.div>
        <animated.div
          className={particleLayerClassName}
          style={{ transitionDelay: '0.9s', transform: animatedProps.y.interpolate(layer3) }}
        >
          <ParallaxParticles className={cx([styles.heroParticles__element, styles.heroParticles__element6])} />
        </animated.div>
      </div>
    </div>
  )
}

HeroBanner.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.string.isRequired,
  big: PropTypes.bool,
  darkNextSection: PropTypes.bool,
}

HeroBanner.defaultProps = {
  big: false,
  darkNextSection: false,
}

export { HeroBanner }
