// extracted by mini-css-extract-plugin
export var heroBanner = "HeroBanner-module--hero-banner--1zwRC";
export var heroBannerContainer = "HeroBanner-module--hero-banner-container--1JQCe";
export var heroBannerContainerDarkOverlay = "HeroBanner-module--hero-banner-container--dark-overlay--lowZw";
export var heroBannerDark = "HeroBanner-module--hero-banner--dark--1Lk1q";
export var heroBanner__text = "HeroBanner-module--hero-banner__text--YT9EH";
export var heroBanner__scrollIncentive = "HeroBanner-module--hero-banner__scroll-incentive--HYXPM";
export var heroBanner__scrollIncentiveDark = "HeroBanner-module--hero-banner__scroll-incentive--dark--iOxnx";
export var heroBanner__scrollIncentive__text = "HeroBanner-module--hero-banner__scroll-incentive__text--3L9Gq";
export var heroBanner__scrollIncentive__icon = "HeroBanner-module--hero-banner__scroll-incentive__icon--18LUV";
export var heroBanner__header = "HeroBanner-module--hero-banner__header--1PS8V";
export var heroBanner__headerBig = "HeroBanner-module--hero-banner__header--big--3ABZA";
export var heroParticles = "HeroBanner-module--hero-particles--3PoZA";
export var hide = "HeroBanner-module--hide--1y6I3";
export var heroParticles__element = "HeroBanner-module--hero-particles__element--2sEAD";
export var heroParticles__element1 = "HeroBanner-module--hero-particles__element1--2WALq";
export var heroParticles__element2 = "HeroBanner-module--hero-particles__element2--2cW8G";
export var heroParticles__element3 = "HeroBanner-module--hero-particles__element3--3r5oh";
export var heroParticles__element4 = "HeroBanner-module--hero-particles__element4--3igWq";
export var heroParticles__element5 = "HeroBanner-module--hero-particles__element5--1pJMp";
export var heroParticles__element6 = "HeroBanner-module--hero-particles__element6--dYWZ7";